import { EndingInputDataBase } from "./ending";

export class FedData extends EndingInputDataBase {
  $type: string =
    "SampleCube.UserFlow.API.Models.EndingPage.DTOs.FedSetStatusRequestApiDTO, SampleCube.UserFlow.API.Models.EndingPage";

  cost: number;
  status: string;
  quota: string;
  public parseUrl(): string {
    return "";
  }
  protected handleLogic(urlParams: {
    [name: string]: string | number | boolean;
  }) {
    if (!!urlParams["cost"]) {
      this.cost = urlParams["cost"] as number;
      this.__logicHandled = true;
    }
    if (!!urlParams["fstatus"]) {
      this.status = urlParams["fstatus"] as string;
      this.__logicHandled = true;
    }
    if (!!urlParams["fquota"]) {
      this.quota = urlParams["fquota"] as string;
      this.__logicHandled = true;
    }
  }
}
