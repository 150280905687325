export class Message {
  static get GenericErrorMessage(): string {
    const message: string = this.__genericErrorMessage[this.__languageCode];
    if (!!!message) {
      return this.__genericErrorMessage[this.__defaultLanguageCode];
    }
    return message;
  }
  static get InvalidRespondent(): string {
    const message: string = this.__invalidRespondent[this.__languageCode];
    if (!!!message) {
      return this.__invalidRespondent[this.__defaultLanguageCode];
    }
    return message;
  }
  static get InvalidReturnStatus(): string {
    const message: string = this.__invalidReturnStatus[this.__languageCode];
    if (!!!message) {
      return this.__invalidReturnStatus[this.__defaultLanguageCode];
    }
    return message;
  }

  static get RespondentNotFound(): string {
    const message: string = this.__respondentNotFound[this.__languageCode];
    if (!!!message) {
      return this.__respondentNotFound[this.__defaultLanguageCode];
    }
    return message;
  }

  static get Revisited(): string {
    const message: string = this.__revisited[this.__languageCode];
    if (!!!message) {
      return this.__revisited[this.__defaultLanguageCode];
    }
    return message;
  }
  static get ThanksForParticipation(): string {
    const message: string = this.__thanksForParticipation[this.__languageCode];
    if (!!!message) {
      return this.__thanksForParticipation[this.__defaultLanguageCode];
    }
    return message;
  }

  static get InvalidNumberErrorMessage(): string {
    const message: string = this.__invalidNumberErrorMessage[this.__languageCode];
    if (!!!message) {
      return this.__invalidNumberErrorMessage[this.__defaultLanguageCode];
    }
    return message;
  }

  static get InvalidEmailErrorMessage(): string {
    const message: string = this.__invalidEmailErrorMessage[this.__languageCode];
    if (!!!message) {
      return this.__invalidEmailErrorMessage[this.__defaultLanguageCode];
    }
    return message;
  }

  static get InvalidMobileErrorMessage(): string {
    const message: string = this.__invalidMobileErrorMessage[this.__languageCode];
    if (!!!message) {
      return this.__invalidMobileErrorMessage[this.__defaultLanguageCode];
    }
    return message;
  }

  static get InvalidDateofbirthErrorMessage(): string {
    const message: string = this.__invalidDateofbirthErrorMessage[this.__languageCode];
    if (!!!message) {
      return this.__invalidDateofbirthErrorMessage[this.__defaultLanguageCode];
    }
    return message;
  }

  static get QuestionRequiredMessage(): string {
    const message: string = this.__questionRequiredMessage[this.__languageCode];
    if (!!!message) {
      return this.__questionRequiredMessage[this.__defaultLanguageCode];
    }
    return message;
  }
  static get InvalidFormatMessage(): string {
    const message: string = this.__invalidPatternMessage[this.__languageCode];
    if (!!!message) {
      return this.__invalidPatternMessage[this.__defaultLanguageCode];
    }
    return message;
  }
  static get MinLengthExceededMessage(): string {
    const message: string = this.__minLengthExceededMessage[this.__languageCode];
    if (!!!message) {
      return this.__minLengthExceededMessage[this.__defaultLanguageCode];
    }
    return message;
  }
  static get MaxLengthExceededMessage(): string {
    const message: string = this.__maxLengthExceededMessage[this.__languageCode];
    if (!!!message) {
      return this.__maxLengthExceededMessage[this.__defaultLanguageCode];
    }
    return message;
  }
  static get MinValueExceededMessage(): string {
    const message: string = this.__minValueExceededMessage[this.__languageCode];
    if (!!!message) {
      return this.__minValueExceededMessage[this.__defaultLanguageCode];
    }
    return message;
  }
  static get MaxValueExceededMessage(): string {
    const message: string = this.__maxValueExceededMessage[this.__languageCode];
    if (!!!message) {
      return this.__maxValueExceededMessage[this.__defaultLanguageCode];
    }
    return message;
  }
  static get MinDateExceededMessage(): string {
    const message: string = this.__minDateExceededMessage[this.__languageCode];
    if (!!!message) {
      return this.__minDateExceededMessage[this.__defaultLanguageCode];
    }
    return message;
  }
  static get MaxDateExceededMessage(): string {
    const message: string = this.__maxDateExceededMessage[this.__languageCode];
    if (!!!message) {
      return this.__maxDateExceededMessage[this.__defaultLanguageCode];
    }
    return message;
  }
  static get MinAnswersExceededMessage(): string {
    const message: string = this.__minAnswersExceededMessage[this.__languageCode];
    if (!!!message) {
      return this.__minAnswersExceededMessage[this.__defaultLanguageCode];
    }
    return message;
  }
  static get MaxAnswersExceededMessage(): string {
    const message: string = this.__maxAnswersExceededMessage[this.__languageCode];
    if (!!!message) {
      return this.__maxAnswersExceededMessage[this.__defaultLanguageCode];
    }
    return message;
  }

  private static __questionRequiredMessage: InternationalMessage = {
    "en-us": "<i>{0}</i> is required."
  };
  private static __invalidPatternMessage: InternationalMessage = {
    "en-us": "<i>{0}</i> must be something like '{1}'"
  };
  private static __minLengthExceededMessage: InternationalMessage = {
    "en-us": "<i>{0}</i> must be minimum {1} characters long."
  };
  private static __maxLengthExceededMessage: InternationalMessage = {
    "en-us": "<i>{0}</i> must be maximum {1} characters long."
  };
  private static __minValueExceededMessage: InternationalMessage = {
    "en-us": "<i>{0}</i> must be minimum {1}."
  };
  private static __maxValueExceededMessage: InternationalMessage = {
    "en-us": "<i>{0}</i> must be maximum {1}."
  };
  private static __minDateExceededMessage: InternationalMessage = {
    "en-us": "<i>{0}</i> must be minimum {1}."
  };
  private static __maxDateExceededMessage: InternationalMessage = {
    "en-us": "<i>{0}</i> must be maximum {1}."
  };
  private static __minAnswersExceededMessage: InternationalMessage = {
    "en-us": "<i>{0}</i> must have minimum {1} selections."
  };
  private static __maxAnswersExceededMessage: InternationalMessage = {
    "en-us": "<i>{0}</i> must have maximum {1} selections."
  };

  private static __genericErrorMessage: InternationalMessage = {
    "en-gb": "Oops! It looks like something went wrong...",
    "en-us": "Oops! It looks like something went wrong..."
  };

  private static __thanksForParticipation: InternationalMessage = {
    "en-gb": "Thanks for participation.",
    "en-us": "Thanks for participation."
  };

  private static __invalidNumberErrorMessage: InternationalMessage = {
    "en-gb": "Please add valid Number.",
    "en-us": "Please add valid Number."
  };

  private static __invalidEmailErrorMessage: InternationalMessage = {
    "en-gb": "Please add valid Email Address.",
    "en-us": "Please add valid Email Address."
  };

  private static __invalidMobileErrorMessage: InternationalMessage = {
    "en-gb": "Please add valid Mobile.",
    "en-us": "Please add valid Mobile."
  };

  private static __invalidDateofbirthErrorMessage: InternationalMessage = {
    "en-gb": "Please add valid Dateofbirth.",
    "en-us": "Please add valid Dateofbirth"
  };

  private static __respondentNotFound: InternationalMessage = Message.__genericErrorMessage;
  private static __revisited: InternationalMessage = Message.__genericErrorMessage;
  private static __invalidRespondent: InternationalMessage = Message.__genericErrorMessage;
  private static __invalidReturnStatus: InternationalMessage = Message.__genericErrorMessage;

  private static __languageCode: string;
  private static __defaultLanguageCode: string = "en-us";
}

// tslint:disable-next-line:interface-over-type-literal
export type InternationalMessage = { [languageCode: string]: string };
