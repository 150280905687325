import { OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { InjectorInstance } from "../../../../core/injector-instance";
import { QuestionBase } from "../../../../models/survey-engine/core/question-base";
import { QuestionAnswerService } from "../../../../services/question-answer.service";
import { Message } from "../../../../localization/message";
import "../../../../extensions/string-extensions";
import { PageLoadService } from "../../../../services/page-load.service";
var QuestionBaseComponent = /** @class */ (function () {
    function QuestionBaseComponent() {
        this.__formBuilder = InjectorInstance.get(FormBuilder);
        this.form = this.__formBuilder.group({});
        this.__questionAnswerService = InjectorInstance.get(QuestionAnswerService);
        this.__requiredErrorMessage = "";
        this.__pageLoadService = InjectorInstance.get(PageLoadService);
        var self = this;
    }
    Object.defineProperty(QuestionBaseComponent.prototype, "requiredErrorMessage", {
        get: function () {
            return this.__requiredErrorMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuestionBaseComponent.prototype, "ctrl", {
        get: function () {
            var self = this;
            if (self.form.contains(self.question.name)) {
                return self.form.get(self.question.name);
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuestionBaseComponent.prototype, "invalid", {
        get: function () {
            var self = this;
            return self.ctrl.invalid && (self.form.dirty || self.form.touched);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuestionBaseComponent.prototype, "title", {
        get: function () {
            return this.question.title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuestionBaseComponent.prototype, "text", {
        get: function () {
            return this.question.text;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuestionBaseComponent.prototype, "name", {
        get: function () {
            return this.question.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuestionBaseComponent.prototype, "toggleText", {
        get: function () {
            return this.question.toggleText;
        },
        enumerable: true,
        configurable: true
    });
    QuestionBaseComponent.prototype.ngOnInit = function () {
        var self = this;
        self.__questionAnswerRegistry = self.__questionAnswerService.getQuestionAnswerRegistry(self.question.name);
        self.ctrl.valueChanges.subscribe(function (value) {
            self.setQuestionAnswer(value);
            self.__questionAnswer.questionId = self.question.id;
            self.__questionAnswerRegistry.setAnswer(self.__questionAnswer);
        });
        self.__requiredErrorMessage = String.format(Message.QuestionRequiredMessage, "Value");
    };
    QuestionBaseComponent.prototype.triggerQuestionLoaded = function () {
        var self = this;
        var registry = self.__pageLoadService.getQuestionLoadRegistry(self.question.name);
        registry.triggerQuestionLoaded();
    };
    QuestionBaseComponent.prototype.ngOnDestroy = function () {
    };
    QuestionBaseComponent.prototype.getFormGroup = function (name) {
        if (this.form.contains(name)) {
            return this.form.get(name);
        }
    };
    QuestionBaseComponent.prototype.getControl = function (name) {
        if (this.form.contains(name)) {
            return this.form.get(name);
        }
    };
    ;
    return QuestionBaseComponent;
}());
export { QuestionBaseComponent };
