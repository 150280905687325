import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocalStorageService, SessionStorageService } from 'ngx-store';

import { environment } from '../environments/environment';
import { AnswerRegistryModule } from './answer-registry.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CategoryRegistryModule } from './category-registry.module';
import { CheckProfileResponseRegistryModule } from './check-profile-response-registry.module';
import { CheckQualificationResponseRegistryModule } from './check-qualification-response-registry.module';
import { EndingInputDataModule } from './components/end-page/ending-input-data.module';
import { EndingOutputDataModule } from './components/end-page/ending-output-data.module';
import { SampleCubeUserModule } from './components/start-page/samplecube-user.module';
import { GlobalErrorHandler } from './core/global-error-handler';
import { API_BASE, APP_INSIGHTS_CONFIG, DAYS_FOR_DUID_REFRESH, ENV_NAME, FINGERPRINT_PRO_KEY, FP_END_POINT, IS_PROD_ENVIRONMENT, LINKED_IN_AUTH, MBD_PIXEL_URL, USE_FINGERPRINT_PRO } from './core/globals';
import { IconMapModule } from './icon-map.module';
import { InjectorModule } from './injector.module';
import { AppInsightsHttpInterceptor } from './interceptors/app-insights-http-interceptor';
import { LoaderHttpInterceptor } from './interceptors/loader-http-interceptor';
import { LoggerHttpInterceptor } from './interceptors/logger-http-interceptor';
import { LayoutComponent } from './layout/layout.component';
import { MaterialModule } from './material.module';
import { MBDCountryRegistryModule } from './mbd-country-registry.module';
import { QuestionRegistryModule } from './question-registry.module';
import { RvidConfigModule } from './rvid-config.module';
import { SampleChainConfigModule } from './sample-chain-config.module';
import { SnackBarAlertComponent } from './snack-bar-alert/snack-bar-alert.component';
import { TopLoaderComponent } from './top-loader/top-loader.component';
import { CoreSurveyDropCookieConfigModule } from './core-survey-drop-cookie-config.module';
import { MatVideoModule } from 'mat-video';
// import { QuestionMapModule } from './question-map.module';
import { RFGFingerPrintConfigModule } from './rfg-fingerprint-config.module';
import { ReconfirmContactConfigModule } from './reconfirm-contact-config.module';
import { config } from 'process';
import { ComScoreConfigModule } from './comscore-image-config.module';

@NgModule({
  entryComponents: [SnackBarAlertComponent],
  declarations: [AppComponent, TopLoaderComponent, SnackBarAlertComponent, LayoutComponent],
  imports: [
    InjectorModule,
    EndingInputDataModule,
    EndingOutputDataModule,
    SampleCubeUserModule,
    // QuestionMapModule,
    MBDCountryRegistryModule,
    QuestionRegistryModule,
    CategoryRegistryModule,
    AnswerRegistryModule,
    CheckProfileResponseRegistryModule,
    CheckQualificationResponseRegistryModule,
    RvidConfigModule,
    SampleChainConfigModule,
    CoreSurveyDropCookieConfigModule,
    IconMapModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    MatSnackBarModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatVideoModule,
    RFGFingerPrintConfigModule,
    ReconfirmContactConfigModule,
    ComScoreConfigModule
  ],
  providers: [
    Title,
    LocalStorageService,
    SessionStorageService,
    {
      provide: API_BASE,
      useValue: environment.baseApi
    },
    {
      provide: LINKED_IN_AUTH,
      useValue: environment.linkedInAuthUrl
    },
    {
      provide: IS_PROD_ENVIRONMENT,
      useValue: environment.production
    },
    {
      provide: ENV_NAME,
      useValue: environment.envName
    },
    {
      provide: MBD_PIXEL_URL,
      useValue: environment.mbdPixelUrl
    },
    {
      provide: APP_INSIGHTS_CONFIG,
      useValue: environment.appInsights
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggerHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInsightsHttpInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
	,
    {
      provide: USE_FINGERPRINT_PRO,
      useValue: environment.useFingerprintPro.val
    }
	,
    {
      provide: FINGERPRINT_PRO_KEY,
      useValue: environment.fingerprintProKey.val
    }
	,
    {
      provide: FP_END_POINT,
      useValue: environment.fpEndpoint.val
    }
	,
    {
      provide: DAYS_FOR_DUID_REFRESH,
      useValue: environment.daysForDuidRefresh.val
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
