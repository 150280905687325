import { Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";

import { InjectorInstance } from "../../../../core/injector-instance";
import { QuestionBase } from "../../../../models/survey-engine/core/question-base";
import { QuestionAnswerService, QuestionAnswerRegistry } from "../../../../services/question-answer.service";
import { QuestionAnswerBase } from "../../../../models/survey-engine/core/question-answer-base";
import { Message } from "../../../../localization/message";

import "../../../../extensions/string-extensions";
import { PageLoadService } from "../../../../services/page-load.service";

export abstract class QuestionBaseComponent implements OnInit, OnDestroy {
  protected __formBuilder: FormBuilder = InjectorInstance.get<FormBuilder>(FormBuilder);

  @Input()
  question: QuestionBase;

  @Input()
  form: FormGroup = this.__formBuilder.group({});

  @Input()
  activeIndex: Observable<number>;

  @Input()
  index: number;

  get requiredErrorMessage(): string {
    return this.__requiredErrorMessage;
  }

  get ctrl(): AbstractControl {
    const self = this;
    if (self.form.contains(self.question.name)) {
      return self.form.get(self.question.name);
    }
    return null;
  }

  get invalid(): boolean {
    const self = this;
    return self.ctrl.invalid && (self.form.dirty || self.form.touched);
  }

  get title(): string {
    return this.question.title;
  }

  get text(): string {
    return this.question.text;
  }

  get name(): string {
    return this.question.name;
  }

  get toggleText(): string {
    return this.question.toggleText;
  }

  constructor() {
    const self = this;
  }

  ngOnInit() {
    const self = this;
    self.__questionAnswerRegistry = self.__questionAnswerService.getQuestionAnswerRegistry(self.question.name);
    self.ctrl.valueChanges.subscribe(value => {
      self.setQuestionAnswer(value);
      self.__questionAnswer.questionId = self.question.id;
      self.__questionAnswerRegistry.setAnswer(self.__questionAnswer);
    });
    self.__requiredErrorMessage = String.format(Message.QuestionRequiredMessage, "Value");
  }
  protected triggerQuestionLoaded() {
    const self = this;
    const registry = self.__pageLoadService.getQuestionLoadRegistry(self.question.name);
    registry.triggerQuestionLoaded();
  }

  ngOnDestroy() {

  }

  getFormGroup(name: string): FormGroup {
    if (this.form.contains(name)) {
      return this.form.get(name) as FormGroup;
    }
  }

  getControl(name: string): FormControl {
    if (this.form.contains(name)) {
      return this.form.get(name) as FormControl;
    }
  }

  protected abstract setQuestionAnswer(value: any);

  protected __questionAnswer: QuestionAnswerBase;
  private __questionAnswerRegistry: QuestionAnswerRegistry;
  private __questionAnswerService: QuestionAnswerService = InjectorInstance.get<QuestionAnswerService>(QuestionAnswerService);
  protected __requiredErrorMessage: string = "";
  protected __pageLoadService: PageLoadService = InjectorInstance.get<PageLoadService>(PageLoadService);;
}
