var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { CategoricalAnswer, CategoricalAnswerCollection } from "../../../../models/survey-engine/core/categorical-answer";
import { MultiAnswer } from "../../../../models/survey-engine/multi-answer";
import { CategoricalQuestionBaseComponent } from "../core/categorical-question-base.component";
import "../../../../extensions/string-extensions";
import { Message } from "../../../../localization/message";
import { AnswerType } from "../../../../models/survey-engine/core/answer-type.enum";
var MultiQuestionComponent = /** @class */ (function (_super) {
    __extends(MultiQuestionComponent, _super);
    function MultiQuestionComponent() {
        var _this = _super.call(this) || this;
        _this.__answers = new CategoricalAnswerCollection();
        return _this;
    }
    MultiQuestionComponent.prototype.setQuestionAnswer = function (value) {
        var self = this;
        self.__questionAnswer = new MultiAnswer();
        value = value || {};
        var answer = new CategoricalAnswerCollection();
        Object.keys(value).forEach(function (code) {
            answer.add(new CategoricalAnswer({
                code: Number(code),
                value: "",
                checked: value[code],
                categoryId: self.getCategory(code).id
            }));
        });
        self.__questionAnswer.answerType = AnswerType.Multi;
        self.__questionAnswer.values = answer; //self.__answers;
    };
    Object.defineProperty(MultiQuestionComponent.prototype, "minAnswersErrorMessage", {
        get: function () {
            return this.__minAnswersErrorMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiQuestionComponent.prototype, "maxAnswersErrorMessage", {
        get: function () {
            return this.__maxAnswersErrorMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiQuestionComponent.prototype, "categories", {
        get: function () {
            var categories = [];
            this.question.categories.$values.forEach(function (c) {
                categories.push(c);
            });
            return categories;
        },
        enumerable: true,
        configurable: true
    });
    MultiQuestionComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        var self = this;
        if (self.question.response) {
            self.__answers = new CategoricalAnswerCollection(self.question.response.values);
        }
        else {
            self.categories.forEach(function (category) {
                self.__answers.$values.push(new CategoricalAnswer({
                    categoryId: category.id,
                    code: category.code
                }));
            });
        }
        var validators = [];
        if (self.question.validators.required) {
            validators.push(CustomMultiValidators.required);
        }
        Object.keys(self.question.validators).forEach(function (validator) {
            if (validator !== "required" && self.question.validators[validator] && CustomMultiValidators[validator]) {
                validators.push(CustomMultiValidators[validator].apply(undefined, [self.question.validators[validator]]));
            }
        });
        self.ctrl.setValidators(validators);
        self.categories.forEach(function (category) {
            var answer = self.getAnswerByCategoryId(category.id);
            var ctrl = self.getCtrl(category.code);
            ctrl.valueChanges.subscribe(function (value) {
                answer.checked = value;
                if (category.isExclusive && value) {
                    self.categories
                        .filter(function (c) { return !c.isExclusive; })
                        .forEach(function (c) {
                        var _ctrl = self.getCtrl(c.code);
                        var _answer = self.getAnswer(c.code);
                        _answer.checked = false;
                        _answer.value = "";
                        _ctrl.setValue(false);
                    });
                }
                if (!category.isExclusive && value) {
                    self.categories
                        .filter(function (c) { return c.isExclusive; })
                        .forEach(function (c) {
                        var _ctrl = self.getCtrl(c.code);
                        var _answer = self.getAnswer(c.code);
                        _answer.checked = false;
                        _answer.value = "";
                        _ctrl.setValue(false);
                    });
                }
            });
            if (answer.checked) {
                ctrl.setValue(answer.checked);
            }
            if (category.isOther && self.otherCtrls) {
                var otherCtrl = self.otherCtrls[category.code];
                otherCtrl.valueChanges.subscribe(function (value) {
                    ctrl.setValue(!!value);
                    answer.value = value;
                });
                if (answer.value) {
                    otherCtrl.setValue(answer.value);
                }
            }
        });
        self.__minAnswersErrorMessage = String.format(Message.MinAnswersExceededMessage, "Value", this.question.validators.minAnswers);
        self.__maxAnswersErrorMessage = String.format(Message.MaxAnswersExceededMessage, "Value", this.question.validators.maxAnswers);
        self.triggerQuestionLoaded();
    };
    MultiQuestionComponent.prototype.getCtrl = function (code) {
        var self = this;
        code = code.toString();
        var fg = self.ctrl;
        if (fg.contains(code)) {
            return fg.get(code);
        }
        return new FormControl();
    };
    MultiQuestionComponent.prototype.getAnswer = function (code) {
        var results = this.__answers.$values.filter(function (item) { return item.code === code; });
        var _answer = results.length ? results[0] : {};
        return _answer;
    };
    MultiQuestionComponent.prototype.getAnswerByCategoryId = function (id) {
        var results = this.__answers.$values.filter(function (item) { return item.categoryId === id; });
        var _answer = results.length ? results[0] : {};
        return _answer;
    };
    MultiQuestionComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return MultiQuestionComponent;
}(CategoricalQuestionBaseComponent));
export { MultiQuestionComponent };
var CustomMultiValidators = /** @class */ (function () {
    function CustomMultiValidators() {
    }
    CustomMultiValidators.required = function (fg) {
        var valid = Object.keys(fg.controls).some(function (k) { return fg.get(k).value; });
        return valid
            ? null
            : {
                required: true
            };
    };
    CustomMultiValidators.minAnswers = function (nr) {
        return function (fg) {
            var count = Object.keys(fg.controls).filter(function (k) { return fg.get(k).value; }).length;
            return count >= nr
                ? null
                : {
                    minAnswers: true
                };
        };
    };
    CustomMultiValidators.maxAnswers = function (nr) {
        return function (fg) {
            var count = Object.keys(fg.controls).filter(function (k) { return fg.get(k).value; }).length;
            return count <= nr
                ? null
                : {
                    maxAnswers: true
                };
        };
    };
    return CustomMultiValidators;
}());
